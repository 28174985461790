@import '@eg/elements/styles/ergo-one/colors';
@import '@eg/elements/styles/breakpoints';
@import '../variables';

.insurance-start-date {
  &__container {
    text-align: center;

    //  Modify the EE RadioTile
    .ee_radio-tile {
      width: 350px !important;
      &__content {
        min-height: unset !important;
      }
    }
    .ee_radio-tile__check-mark {
      left: 20px !important;
      top: 15px !important;
    }
    .ee_radio-tile__footer {
      padding: 0px !important;
    }
  }

  &__page-title {
    @include pageTitle;
    margin-bottom: 1.5rem;
  }

  &__option-wrapper {
    display: grid;
    justify-content: center;
  }

  &__option:last-child {
    .insurance-start-date__option-radio.ee_radio-tile {
      padding-bottom: 0 !important;
    }
  }

  &__option-radio {
    width: 100%;
    &__sub {
      float: left;
      margin-left: 1rem;
    }
  }

  &__option-label {
    font-size: 0.9rem;
    font-weight: bold;
    text-align: left;
  }

  &__option-date {
    font-size: 0.8rem;
    padding-top: 5px;
    text-align: left;
  }

  &__input {
    margin: 2rem 0 0 0;
  }

  &__input-title {
    font-size: 0.9rem;
    text-align: center;
    margin: 2rem 0 2rem 0;
  }

  &__input-container {
    display: inline-block;
  }

  &__input-datepicker {
    @include datepickerContainer;
  }

  &__error {
    @include errorMessage;
    margin-bottom: 0;
  }

  /**
   * Desktop
   */
  @media (min-width: $large-screen__min-width) {
    &__option-container {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: stretch;
      align-content: center;
      max-width: $extra-large-screen__min-width;
    }

    &__option {
      width: 28%;
    }

    &__input-container {
      margin: 0 auto;
      max-width: $medium-screen__min-width;
    }
  }
}
