@import '@eg/elements/styles/ergo-one/colors';
@import '@eg/elements/styles/breakpoints';

.tarif-overview {
  &__container {
    display: grid;
    justify-content: center;
  }

  &__divider {
    height: 3rem;
    border-bottom: 1px solid #e1e1e1;
  }
  &__summary {
    max-width: 872px;
  }
  &__summary-title {
    font-size: 18px;
    font-weight: bold;
  }
  &__summary-param {
    display: grid;
    grid-template-columns: 1fr 1.7fr;
    gap: 3rem;
    align-items: center;
    @media (max-width: ($breakpoint-m - 1)) {
      grid-template-columns: 1fr;
      gap: 0rem;
    }
  }
  &__navigation-button {
    display: grid;
    @media (max-width: ($breakpoint-m - 1)) {
      display: block;
    }
    .ee_button__wrapper {
      padding: 0 !important;
    }
  }

  &__info-text {
    margin-top: 2rem;
    text-align: center;
    padding: 2px 2px 2px 1rem;
    font-size: 14px;
    line-height: 20px;
    @media (max-width: ($breakpoint-l - 1)) {
      padding: 1px 1px 1px 0.5rem;
      font-size: 12px;
    }

    
  }
}
