@import '@eg/elements/styles/ergo-one/colors';
@import '@eg/elements/styles/breakpoints';
@import '../variables';

.thank-you {
  &__container {
    display: grid;
    justify-content: center;
  }
  &__hero {
    background-color: #e3f0e6;
    text-align: center;
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
    padding-top: 64px;
    padding-bottom: 64px;
    @media (max-width: ($breakpoint-l - 1)) {
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }

  &__hero-title {
    font-family: 'Fedra Serif', 'FS Me', Arial, Helvetica, sans-serif;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    color: $theme-primary;
    hyphens: none;
    font-size: 40px !important;
    line-height: 52px;
    @media (max-width: ($breakpoint-l - 1)) {
      font-size: 24px !important;
      line-height: 32px;
      padding: 1rem 4rem;
    }
  }
  &__hero-subtitle {
    font-size: 18px !important;
    line-height: 24px;
    @media (max-width: ($breakpoint-l - 1)) {
      font-size: 14px !important;
      line-height: 20px;
      max-width: 240px;
      margin: auto;
    }
  }

  &__body-container {
    display: grid;
    justify-content: center;
    grid-template-columns: 864px;
    margin-top: 64px;
    row-gap: 64px;
    @media (max-width: ($breakpoint-l - 1)) {
      margin-top: 32px;
      grid-template-columns: none;
      row-gap: 32px;
    }
  }

  &__title {
    text-align: center;
    font-size: 28px;
    line-height: 36px;
    margin: 0;
    margin-bottom: 20px;
    @media (max-width: ($breakpoint-l - 1)) {
      font-size: 20px;
      line-height: 28px;
    }
  }
  &__subtitle {
    line-height: 24px;
    text-align: center;
    font-size: 16px;
    @media (max-width: ($breakpoint-l - 1)) {
      line-height: 20px;
      font-size: 14px;
    }
  }

  &__summary {
    display: grid;
    row-gap: 1rem;
    font-size: 16px;
    div:nth-child(2) > p {
      display: flex;
      flex-flow: column;
      line-height: 24px;
    }
  }
  &__summary-param {
    display: grid;
    grid-template-columns: 1fr 2fr;
    row-gap: 1rem;
    align-items: center;
    @media (max-width: ($breakpoint-l - 1)) {
      grid-template-columns: 1fr;
      gap: 0rem;
    }
  }

  &__AEM {
    text-align: center;
    background: #efe7ed;
  }
}

#thank-you-content {
  margin: 0;
}
