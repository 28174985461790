@import 'variables';

#rs-root {
  font-family: 'FS Me', Arial, Helvetica, sans-serif;
}

$container-width: 100%;

.app {
  &__header {
    background: #e2f6fa;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
  &__section {
    // margin: 0 1.1rem;
  }

  &__navigation {
    padding: 48px 0 76px 0;
  }
  &__navigation-download-page {
    padding-bottom: 76px;
  }

  &__footer {
    width: 100%;
  }

  .ee_form-footer {
    margin-top: 0 !important;
  }

  &__esc_container {
    max-width: 1440px;
    margin: 0 auto;
  }

  &__full-grid {
    max-width: 1280px;
    margin: auto;
  }

  &__aem-GridColumn {
    border-left: solid transparent;
    border-right: solid transparent;
    border-width: 24px;
  }
}

.page {
  &__title {
    @include pageTitle;
    margin-bottom: 1.5rem;
    @media (max-width: ($breakpoint-l - 1)) {
      padding: 0;
    }
  }
  &__subtitle {
    p {
      margin: 0;
      text-align: center;
      font-size: 16px;
    }
    margin-bottom: 1rem;
  }
}

// Global tooltip styling
.tooltip {
  color: #333333 !important;

  h1,
  h2,
  h3{
    font-size: 20px !important;
    line-height: 28px !important;
    @media (max-width: ($breakpoint-m - 1)) {
      font-size: 18px !important;
      line-height: 24px !important;
    }
  }

  h4 {
    font-size: 16px !important;
    line-height: 24px !important;
    @media (max-width: ($breakpoint-m - 1)) {
      font-size: 14px !important;
      line-height: 20px !important;
    }
    
  }

  p {
    font-size: 16px !important;
    line-height: 24px !important;
    @media (max-width: ($breakpoint-m - 1)) {
      font-size: 14px !important;
      line-height: 20px !important;
    }
  }

  > div {
    font-size: 16px !important;
    line-height: 24px !important;
    @media (max-width: ($breakpoint-m - 1)) {
      font-size: 14px !important;
      line-height: 20px !important;
    }
  }

  ul{
    padding-left: 20px !important;
    line-height: 24px !important;
    font-size: 16px !important;
  }

  &__dimmed {
    ol,
    ul,
    p {
      color: $theme-darker-gray !important;
      font-size: 12px !important;
      line-height: 18px !important;
      @media (max-width: ($breakpoint-m - 1)) {
        font-size: 10px !important;
        line-height: 16px !important;
      }
    }
  }

  a {
    color: #8e0038 !important;
    text-decoration: none !important;
  }
}

#link {
  color: #8e0038;
}
// Adjusting EE to the project
/* Adjusting the tooltip model width {@eg/elements/TooltipIcon} */
.ee_modal__container {
  max-width: 52em !important;
}

.ee_tooltip-wrapper {
  display: block !important;
}

.ee_radio-tile {
  padding: 12px !important;
}

.ee_radio-tile__control + .ee_radio-tile__wrapper {
  transform: none !important;
  & > .ee_radio-tile__tile:hover {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12) !important;
    .ee_radio__label::before {
      box-shadow: 0 0 0 10px rgba(115, 115, 115, 0.04);
    }
  }
  & > .ee_radio-tile__tile {
    box-shadow: 0 0 0 1.6px rgba(0, 0, 0, 0.2) !important;
  }
}

.ee_radio-tile__control:checked:not([disabled]) + .ee_radio-tile__wrapper > .ee_radio-tile__tile {
  box-shadow: 0 0 0 1.6px #8e0038 !important;
  .ee_radio__label::before {
    box-shadow: none !important;
  }
}

.ee_radio__control:focus:checked + .ee_radio__label::before {
  box-shadow: none !important;
}

.subheader {
  padding-bottom: 1rem;
}

.marital-status__page-title {
  margin-bottom: 1.5rem;
}

.header {
  position: sticky;
  z-index: 100;
  top: 0px;
}
.progress-bar__container {
  max-width: unset !important;
  background-color: white;
}

.app_container__section {
  margin: 0 1.5rem;
}

.ee_tooltip-icon__icon {
  color: #333 !important;
}

.ee_date-input__control--day {
  flex-grow: 3 !important;
  @media (max-width: ($breakpoint-l - 1)) {
    flex-grow: 1 !important;
  }
}
.ee_date-input__control--month {
  flex-grow: 3 !important;
  @media (max-width: ($breakpoint-l - 1)) {
    flex-grow: 1 !important;
  }
}
.ee_date-input__control .ee_date-input__input > input {
  text-align: center !important;
  text-indent: 0 !important;
}

/* Hide Arrows From Input Number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield !important;
}
