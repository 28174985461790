@import '@eg/elements/styles/ergo-one/colors';
@import '@eg/elements/styles/breakpoints';
@import '../variables';

.personal-data {
  &__container {
    display: grid;
    justify-content: center;
  }

  &__title {
    @include pageTitle;
    margin-bottom: 1.5rem;
  }

  &__subtitle {
    text-align: center;
  }

  &__label {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    font-size: 14px;
  }
  &__section-title {
    font-size: 18px;
    @media (max-width: ($breakpoint-m - 1)) {
      font-size: 16px;
    }
  }

  &__inputs-container {
    display: grid;
    row-gap: 1rem;
  }

  &__birthdate {
    display: flex;
    align-items: center;
  }

  &__input {
    display: inline-block;
    margin-top: 2rem;
    height: 5.2rem;
  }

  &__error {
    @include errorMessage;
  }

  &__input-datepicker {
    @include datepickerContainer;
  }

  &__profit-info-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.7rem;
  }

  &__info-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    line-height: 1.125rem;
    height: 2rem;
    margin-bottom: 2rem;
    @media (max-width: ($breakpoint-m - 1)) {
      font-size: 0.76rem;
      text-align: left;
    }
  }

  &__tooltip {
    h1 {
      margin-bottom: 2rem;
    }
  }

  &__email-label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  &__phone {
    &-prefix {
      display: flex;

      &__country {
        width: 6rem;
        margin-right: 1.5rem;
      }
      &__number {
        @media (max-width: ($breakpoint-m - 1)) {
          flex-grow: 1;
        }
      }
    }
    .ee_form-row:last-child {
      margin-bottom: 0 !important;
      .ee_form-row__item {
        margin-bottom: 0;
      }
    }
  }
}

.phone-text {
  font-size: 14px;
  &__body {
    margin-bottom: 0;
  }
}
.phone-row {
  .ee_form-row__item-container {
    max-width: unset !important;
  }
}
#email {
  margin-top: 1rem;
  @media (max-width: ($breakpoint-m - 1)) {
    margin-top: 0;
  }
}

/** make the width of the email input 100%*/
.ee_form-row__item-container {
  max-width: 565.35px !important;
}

#area {
  color: black !important;
  background-color: transparent !important;
  border: none;
  display: flex;
  align-items: center;
}
