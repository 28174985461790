@import '~@eg/elements/styles/breakpoints';
@import '~@eg/elements/styles/ergo-one/colors';

@mixin pageTitle {
  font-family: 'Fedra Serif', 'FS Me', Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 36px;
  letter-spacing: 0;
  margin-top: 4rem;
  margin-bottom: 4rem;
  text-align: center;
  color: $theme-primary;
  padding: 0 2rem;
  hyphens: none;

  @media (max-width: ($breakpoint-m - 1)) {
    font-size: 24px;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

@mixin priceHeaderContainerPage {
  @media (max-width: ($breakpoint-m - 1)) {
    padding: 1rem 0 2rem;
  }
}

@mixin datepickerContainer {
  width: 300px;
  margin: auto;
}

@mixin errorMessage {
  font-size: 12px;
  line-height: 2em;
  letter-spacing: 0.25px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  color: $theme-error-color;
}
