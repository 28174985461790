@import '../variables';

.tariff-data {
  &__container {
    display: block;
    text-align: center;
    width: 100%;

    .self-employed-info {
      font-size: 14px;
    }

    .radio-title {
      font-size: 18px;
    }

    .overview-button {
      margin: 2rem;
      @media (max-width: ($breakpoint-s)) {
        margin: 0;
      }
    }

    .ee_radiotabs {
      @media (max-width: ($breakpoint-s)) {
        padding: 0 !important;
      }
      $breakpoint-s: 480px;

      &__panellist {
        max-width: 912px;
        margin: auto;
      }
    }
    .ee_radiotabs__tab:first-child {
      border-radius: 10px 0 0 10px !important;
    }
    .ee_radiotabs__tab:last-child {
      border-radius: 0 10px 10px 0 !important;
    }

    .ee_radiotabs__panellist {
      padding: 0 !important;
      border: none !important;
      box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12) !important;
    }

    .ee_checkbox {
      flex-grow: 1;
      padding: unset !important;
    }

    .ee_checkbox__label {
      flex-grow: 1;
      text-align: left;
    }

    .benefit-list {
      list-style: none;
      text-align: left;

      @media (max-width: ($breakpoint-s)) {
        padding: 0 !important;
      }

      > li {
        padding: 1rem 0;
        > div {
          display: flex;
          align-items: center;
          @media (max-width: ($breakpoint-s)) {
            align-items: flex-start;
          }
        }
        strong {
          margin-right: auto;
          margin-left: 1.4em;
        }
        border-bottom: 1px solid #d9d9d9;
        @media (max-width: ($breakpoint-s)) {
          border-bottom: none;
        }
      }

      > li:last-child {
        border: none;
      }
      .ee_tooltip-icon__button {
        align-items: flex-start !important;
        @media (max-width: ($breakpoint-s)) {
          padding: 0 !important;
        }
      }

      .ee_tooltip-wrapper {
        margin-left: auto;
      }

      .subtext {
        font-size: 14px;
        text-align: left;
        margin-left: calc(25px + 1.4em);
        margin-top: 0.2rem;
      }

      .ee_icon-checkcoloricon {
        flex-shrink: 0;
      }
    }

    .checkbox-list {
      list-style: none;
      text-align: left;

      @media (max-width: ($breakpoint-s)) {
        padding: 0 !important;
      }

      > li {
        padding: 1rem 0;
        > div {
          display: flex;
        }
        .ee_checkbox {
          align-items: flex-start !important;
        }
        border-bottom: 1px solid #d9d9d9;
        @media (max-width: ($breakpoint-s)) {
          border-bottom: none;
        }
      }

      > li:last-child {
        border: none;
      }

      .ee_tooltip-wrapper {
        margin-left: auto;
      }

      .subtext {
        font-size: 14px;
        text-align: left;
        margin-top: 0.2rem;

        @media (max-width: ($breakpoint-s)) {
          font-size: 12px;
        }
      }

      .ee_icon-checkcoloricon {
        flex-shrink: 0;
      }
    }

    .ee_radiotabs__tab__title {
      white-space: pre-line;
    }
  }

  &__pdf-text {
    display: inline-block;
    vertical-align: middle;
    color: $theme-brand-color;
    text-decoration: none;
  }

  &__tab-container {
    padding: 4rem 10% 2rem 10%;
    border-bottom: 1px solid #d9d9d9;
  }

  &__secondary-tab-container {
    padding: 2rem 10% 2rem 10%;
    padding-bottom: 30px;
  }

  &__select-container {
    margin: 2rem auto;
    display: inline-block;
  }

  &__page-title {
    @include pageTitle;
    margin-bottom: 1.5rem;
  }

  &__dropdowns {
    display: inline-block;
    min-width: 300px;

    @media (min-width: ($breakpoint-m)) {
      min-width: 600px;
    }
    margin: 1.5rem auto 0 auto;

    .ee_tooltip-wrapper {
      @media (max-width: ($breakpoint-m)) {
        margin-left: auto;
      }
    }

    .ee_form-row__item-container {
      padding-right: 0 !important;
    }
    .ee_form-row:last-child {
      margin: 0 !important;
      .ee_form-row__descriptor {
        padding-bottom: 0 !important;
      }
      .ee_form-row__item {
        margin-bottom: 0 !important;
      }
    }
  }

  .subheader {
    padding-bottom: 1rem;
  }

  &__checkbox-error {
    color: #e80c26;
    line-height: 20px;
    font-size: 14px;
    margin-top: 24px;
  }
  &__pdf-download {
    margin-top: 48px;
    .ee_button__content {
      @media (max-width: ($breakpoint-s)) {
        display: block !important;
        font-size: 13px !important;
      }
    }
  }
}

#tariff-data {
  &_interval,
  &_contribution {
    margin-bottom: 1.7rem;
  }
}

.duration-select-responsive-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ($breakpoint-l)) {
    justify-content: start;
  }
}
.duration-select-responsive-label {
  margin-right: 8;
  @media (max-width: ($breakpoint-l)) {
    margin-right: 0;
  }
}
