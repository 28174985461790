@import '~@eg/elements/styles/ergo-one/colors';
@import '../variables';

.marital-status {
  &__container {
    text-align: center;
  }

  &__radio {
    padding: 0px 0 0px 20px !important;
  }

  &__select-container {
    display: flex;
    justify-content: center;
    @media (max-width: ($breakpoint-l - 1)) {
      display: none;
    }
    margin: 0 auto;

    .ee_radio-tile-group__legend {
      display: none;
    }

    .ee_radio-tile {
      padding-bottom: 0 !important;
      width: 180px !important;
    }

    #rs-root & .ee_radio-tile__check-mark {
      /** centering the checkmark icon */
      right: 50%;
      margin-right: -11px; // it is 22px wide, so negative margin of half will "center it"
    }
  }

  &__select-container-mobile {
    @media (min-width: ($breakpoint-l)) {
      display: none;
    }
    margin: 0 auto;
    display: block;
  }

  &__option-radio {
    width: 100%;
  }

  &__single-radio-row {
    border: 1px solid $theme-medium-gray;
    border-radius: 1rem;
    text-align: left;
    display: block;
    margin: 0 0 1rem;
    padding: 0.25rem 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__single-radio-row.selected {
    background: rgb(238, 231, 237);
    border: 1px solid #8e0038;
  }

  &__icon-container {
    width: 3.75rem;
    height: 3.75rem;
    margin: 1.13rem auto 0.68rem;
  }

  &__tooltip-content {
    h4 {
      margin: 0 !important;
    }

    p {
      margin-top: 0 !important;
    }
  }
}
