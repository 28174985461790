@import '~@eg/elements/styles/ergo-one/colors';
@import '~@eg/elements/styles/breakpoints';

$progress-bar-height: 4px;

.progress-bar {
  &__container {
    height: $progress-bar-height;
    margin: auto;
    background-color: white;
  }

  &__progress {
    height: $progress-bar-height;
    background-color: $theme-brand-color;
  }
}
