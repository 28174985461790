@import '~@eg/elements/styles/ergo-one/colors';
@import '@eg/elements/styles/breakpoints';
@import '../variables';

.navigation-buttons {
  text-align: center;
  &__back-link-wrapper {
    margin: 1rem 0 0 0;
  }
  &__back-link {
    font-size: 0.9em;
    font-weight: bold;
    color: $theme-darker-gray;
    text-decoration: none;
    margin: 1rem 0 0 0;
  }

  &__error {
    @include errorMessage;
  }
}

.submit-button {
  background: $theme-primary !important;
  @media (min-width: ($breakpoint-m - 1)) {
    width: 275px;
  }
}
