@import '../variables';
@import '~@eg/elements/styles/ergo-one/colors';

.payment {
  &__container {
    text-align: center;
    margin: 0 auto;
  }

  &__form {
    max-width: 600px;
    margin: 0 auto 2rem;
    padding-bottom: 0px !important;
    .ee_form-row__item-container {
      min-width: 100%;
    }
  }

  &__information {
    max-width: 900px;
    text-align: left;
    margin: auto;
  }
  &__note {
    font-size: 14px;
    &-sepa {
      display: flex;
      align-items: center;
    }
  }
  &__validation {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2.5rem;
    &-text {
      text-align: left;
      padding: 2px 2px 2px 1rem;
      font-size: 14px;
      line-height: 20px;
    }
    .ee_icon-checkcoloricon {
      align-self: baseline;
      margin-top: 13px;
      flex-shrink: 0;
    }
  }
}
