@import '../variables';
@import '~@eg/elements/styles/ergo-one/colors';

.employment-situation {
  &__container {
    text-align: center;

    .ee_radio-tile {
      width: 420px !important;
      &__content {
        min-height: unset !important;
      }
      &:last-child {
        padding-bottom: 0 !important;
      }
    }
    .ee_radio-tile__check-mark {
      left: 20px !important;
      top: 15px !important;
    }
    .ee_radio-tile__footer {
      padding: 0px !important;
    }
  }

  &__icon-description {
    float: left;
    margin-left: 1rem;
    text-align: left;
  }

  &__offical-hint {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 48px 0 0;
    &-text {
      width: 480px;
      text-align: left;
      padding: 2px 2px 2px 1rem;
      font-size: 14px;
      line-height: 20px;
      @media (max-width: ($breakpoint-l - 1)) {
        padding: 1px 1px 1px 0.5rem;
        font-size: 12px;
      }
    }
    .ee_icon-checkcoloricon {
      align-self: baseline;
      flex-shrink: 0;
    }
  }

  &__select-container {
    @media (max-width: ($breakpoint-l - 1)) {
      display: none;
    }
    margin: auto auto 0 auto;
    display: flex;
    align-items: center;
    flex-flow: column;
  }

  &__select-container-mobile {
    margin: 0 auto;
    @media (min-width: ($breakpoint-l)) {
      display: none;
    }
  }

  &__single-radio-row {
    border: 1px solid $theme-medium-gray;
    border-radius: 0.5rem;
    text-align: left;
    display: block;
    margin: 0 0 1rem;
    padding: 0.25rem 1.25rem;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  &__single-radio-row.selected {
    background: rgb(238, 231, 237);
    border: 1px solid #8e0038;
  }

  &__option-radio {
    width: 100% !important;
  }

  &__tooltip-body {
    padding-left: 20px;
  }
}
