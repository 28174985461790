@import '../variables';
@import '~@eg/elements/styles/ergo-one/colors';

.pre-insurance {
  &__container {
    text-align: center;

    .ee_radio-tile {
      width: 272px !important;
      padding-bottom: 0px !important;
      &__content {
        min-height: unset !important;
      }
    }

    .ee_form-row__item-container {
      max-width: unset !important;
      padding-right: 0 !important;
    }

    .ee_radio-tile__check-mark {
      left: 20px !important;
      top: 15px !important;
    }
    .ee_radio-tile__footer {
      padding: 0px !important;
    }
    .pre-insurance__icon-description {
      text-align: left;
      float: left;
      margin-left: 1rem;
    }
  }

  &__insurance-role {
    .ee_form-row__hint {
      padding-left: 0px !important;
    }
    .ee_form-row__container {
      flex-flow: column;
    }
    @media (max-width: ($breakpoint-l - 1)) {
      width: 100%;
    }
    .ee_form-row__item-container {
      text-align: left !important;
    }
  }
  &__date-input {
    .ee_form-row:last-child {
      margin: 0 !important;
    }
    .ee_form-row__descriptor {
      padding-bottom: 0 !important;
    }
    .ee_form-row__item,
    .ee_form-row__messages {
      margin-bottom: 0;
      margin-top: 0 !important;
    }
    margin: 0;
    width: 80%;
    @media (max-width: ($breakpoint-l - 1)) {
      width: 100%;
    }
  }

  &__date-label {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
  }

  &__date {
    margin: 1em 0 !important;
  }

  &__select-container {
    @media (max-width: ($breakpoint-l - 1)) {
      display: none;
    }
    margin: 0.5rem auto 0 auto;
    display: flex;
    justify-content: center;
  }

  &__select-container-mobile {
    margin: 0 auto;

    @media (min-width: ($breakpoint-l)) {
      display: none;
    }
  }

  &__option-radio {
    width: 100%;
  }

  &__single-radio-row {
    border: 1px solid $theme-medium-gray;
    border-radius: 1rem;
    text-align: left;
    display: block;
    margin: 0 0 1rem;
    padding: 0.25rem 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__single-radio-row.selected {
    background: rgb(238, 231, 237);
    border: 1px solid #8e0038;
  }

  &__input {
    text-align: left;
  }

  &__input-container {
    margin: auto;
    max-width: 912px;
    padding-top: 64px;
  }

  &__input-title {
    margin-bottom: 48px;
    text-align: center;
  }

  &__checkbox-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px !important;
    line-height: 20px !important;
    text-align: left;
    margin: 2rem auto 0;
    max-width: 720px;
    .ee_icon {
      align-self: baseline;
      flex-shrink: 0;
      margin-right: 1rem;
    }
    a {
      color: $theme-secondary;
      text-decoration: none;
    }
  }

  &__dropdown {
    @media (min-width: $breakpoint-m) {
      max-width: 350px;
      margin: calc(1px + 0.5em) 0;
    }
  }
}

.ee_tooltip-wrapper {
  display: block !important;
}
