@import '@eg/elements/styles/ergo-one/colors';
@import '@eg/elements/styles/breakpoints';
@import '../variables';

.birthday-date {
  &__container {
    text-align: center;
  }

  &__title {
    @include pageTitle;
    margin-bottom: 1.5rem;
  }

  &__subtitle {
    p {
      margin: 0;
    }
  }
  &__input {
    display: inline-block;
    margin-top: 1rem;
  }

  &__error {
    @include errorMessage;
    margin-bottom: 0;
  }

  &__input-datepicker {
    @include datepickerContainer;
  }

  &__profit-info-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.7rem;
  }

  &__info-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    line-height: 1.125rem;
    margin-top: 1.5rem;
    @media (max-width: ($breakpoint-m - 1)) {
      font-size: 0.76rem;
      text-align: left;
    }
    p {
      margin: 0;
    }
  }

  &__tooltip {
    h1 {
      margin-bottom: 2rem;
    }
  }
}
