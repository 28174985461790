@import '../variables.scss';

.error-handling-modal {
  &__title {
    font-size: 20px !important;
    line-height: 28px !important;
    @media (max-width: ($breakpoint-m - 1)) {
      font-size: 18px !important;
      line-height: 24px !important;
    }
  }
  &__button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
