@import '../variables';
@import '~@eg/elements/styles/ergo-one/colors';

.employment {
  &__container {
    text-align: center;

    .ee_radio-tile {
      width: 272px !important;
      padding-bottom: 0px !important;
      &__content {
        min-height: unset !important;
      }
    }
    .ee_radio-tile__check-mark {
      left: 20px !important;
      top: 15px !important;
    }
    .ee_radio-tile__footer {
      padding: 0px !important;
    }
    .employment__icon-description {
      text-align: left;
      float: left;
      margin-left: 1rem;
    }
  }

  &__select-container {
    @media (max-width: ($breakpoint-l - 1)) {
      display: none;
    }
    margin: 0.5rem auto 0 auto;
    display: flex;
    justify-content: center;
  }

  &__select-container-mobile {
    margin: 0 auto;

    @media (min-width: ($breakpoint-l)) {
      display: none;
    }
  }

  &__option-radio {
    width: 100%;
  }

  &__single-radio-row {
    border: 1px solid $theme-medium-gray;
    border-radius: 1rem;
    text-align: left;
    display: block;
    margin: 0 0 1rem;
    padding: 0.25rem 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__single-radio-row.selected {
    background: rgb(238, 231, 237);
    border: 1px solid #8e0038;
  }
}

.ee_tooltip-wrapper {
  display: block !important;
}
