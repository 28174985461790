@use 'sass:math';
@import '~@eg/elements/styles/ergo-one/colors';
@import '~@eg/elements/styles/breakpoints';

$price-tag-height: 3rem;

.price-header {
  font-size: 14px;
  margin: 0 1.2rem;
  padding: 0 20px;
  &__price {
    font-weight: bold;
  }

  &__container {
    margin: 0 auto;
    max-width: $breakpoint-l;
  }

  &__label {
    font-size: 16px;
    font-weight: bold;
  }

  &__text:only-child {
    margin: auto;
  }

  &__description {
    margin-top: 0.4rem;
  }

  &__price-field {
    display: inline-block;
    height: $price-tag-height;
    font-size: 16px;
    white-space: nowrap;
  }

  &__price {
    font-size: 28px;
    margin-right: 0.3rem;
    line-height: $price-tag-height;
  }

  @media (max-width: ($breakpoint-m - 1)) {
    &__container {
      text-align: center;
    }

    &__label {
      font-size: 14px;
    }

    &__text {
      font-size: 12px;
      padding: 1.3rem 0 0;
    }

    &__text:only-child {
      padding: 1.3rem 0;
    }

    &__price-field {
      padding: 0 2rem;
      font-size: 14px;
    }

    &__price {
      font-size: 18px;
    }
  }

  &__label-only {
    padding: 1.5rem;
    font-weight: bold;
    text-align: center;
    font-size: 1rem;

    @media (max-width: ($breakpoint-m - 1)) {
      padding: 1rem;
    }
  }

  @media (min-width: $breakpoint-m) {
    &__container {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 1.3rem 0;
    }
  }
}
