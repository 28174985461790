@import '@eg/elements/styles/ergo-one/colors';
@import '@eg/elements/styles/breakpoints';
@import '../variables';

.download {
  &__container {
    .ee_card {
      max-width: 718px !important;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__title {
    @include pageTitle;
    margin-bottom: 1.5rem;
  }

  &__subtitle {
    text-align: center;
  }
  &__card-container {
    display: grid;
    justify-content: center;
    grid-template-columns: 0.85fr;
    @media (max-width: ($breakpoint-m - 1)) {
      display: block;
    }
  }
  &__card {
    padding: 0 !important;
  }
  &__law {
    h3 {
      margin-bottom: 2rem;
    }
    padding: 32px 48px 32px 48px;

    @media (max-width: ($breakpoint-m - 1)) {
      padding: 2rem;
    }
    .p {
      text-align: justify;
    }
  }

  &__error-message {
    @include errorMessage;
  }

  &__law-confirmation {
    padding: 24px 40px 32px 48px;
    background-color: #efe7ed;
    @media (max-width: ($breakpoint-m - 1)) {
      padding-left: 1rem;
      padding-right: 0rem;
      height: auto;
    }

    .ee_checkbox {
      align-items: flex-start !important;
      font-size: 16px !important;
      @media (max-width: ($breakpoint-m - 1)) {
        font-size: 14px !important;
      }
    }
  }

  &__error-message {
    font-size: 14px;
    padding: 0;
    margin-left: -0.5rem;
    margin-top: 0;
    @media (max-width: ($breakpoint-m - 1)) {
      font-size: 12px;
    }
  }
  &__step1 {
    text-align: center;
    margin: 2rem;
    line-height: 20px;
    @media (max-width: ($breakpoint-m - 1)) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  &__step2 {
    text-align: center;
  }
  &__step-text {
    margin-bottom: 24px;
    font-size: 14px;
  }

  &__option-button {
    width: 225px;
    @media (min-width: ($breakpoint-m - 1)) {
      width: 275px;
    }
  }
}
